import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getDevicesListAPI = createAsyncThunk(
  "GET/GET-DEVICES-LIST",
  async (params) => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/license/list-all-devices/${getOrganizationSwithId()}/${
        params.license_id
      }/${params.license_key}`,
      baseURL: config.BASE_URL,
      // reqData: params,
    });
  }
);

const AllDevicesDataSlice = createSlice({
  name: "getAllDevicesData",
  initialState,
  reducers: {
    resetGetDevicesListAPI: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDevicesListAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDevicesListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getDevicesListAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error =
        action.payload !== undefined
          ? action.payload
          : "Oops, Requested data was not found";
    });
  },
});

export const { resetGetDevicesListAPI } = AllDevicesDataSlice.actions;

const AllDevicesAPIPage = combineReducers({
  getAllDevices: AllDevicesDataSlice.reducer,
});

export default AllDevicesAPIPage;
