import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getLicensesListAPI = createAsyncThunk(
  "GET/GET-LICENSES-LIST",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/license/list-all-licenses/${getOrganizationSwithId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const getAllLicenseDataSlice = createSlice({
  name: "getAllLicensesData",
  initialState,
  reducers: {
    resetGetLicensesListAPI: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLicensesListAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLicensesListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getLicensesListAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error =
        action.payload !== undefined
          ? action.payload
          : "Oops, Requested data was not found";
    });
  },
});

export const { resetGetLicensesListAPI } = getAllLicenseDataSlice.actions;

const AllLicenseAPIPage = combineReducers({
  getAllLicenses: getAllLicenseDataSlice.reducer,
});

export default AllLicenseAPIPage;
